.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	fill: $svgColor;
}

.icon-address {
	font-size:(430.11/10)*1rem;
	width:(430.11/430.11)*1em;
}
.icon-cart {
	font-size:(554.75/10)*1rem;
	width:(554.75/554.75)*1em;
}
.icon-fb {
	font-size:(96.12/10)*1rem;
	width:(96.12/96.12)*1em;
}
.icon-inst {
	font-size:(512/10)*1rem;
	width:(512/512)*1em;
}
.icon-od {
	font-size:(95.48/10)*1rem;
	width:(95.48/95.48)*1em;
}
.icon-phone {
	font-size:(402/10)*1rem;
	width:(402/402)*1em;
}
.icon-vk {
	font-size:(304.36/10)*1rem;
	width:(304.36/304.36)*1em;
}
