* {
  box-sizing: border-box;
  outline: none !important;
}

// html {
//   font-size: 10px;
//   // font-family: $font-family;
//   // font-weight: $font-book;
//   color: $color-black;
//   scroll-behavior: smooth;
// }

// img {
//   max-width: 100%;
//   height: auto;
// }

// a {
//   text-decoration: none;
// }

// section {
//   padding: 30px 0;
// }
.text-center {
  text-align: center;
}

.product-thumb {
  transition: 0.2s all linear;
  box-shadow: 0 3px 20px rgba(#ffffff, 0.3);

  &:hover {
    box-shadow: 0 3px 20px rgba(#000000, 0.3);
  }

  .caption {
    p {
      font-size: 14px;
      max-height: 80px;
      overflow: hidden;

      @include phone {
        max-height: 60px;
      }
    }

    @include phone {
      padding: 0 10px;
      min-height: 100px;
    }
  }

  h4 {
    @include phone {
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      height: 18px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .image {
    @include phone {
      height: 260px;

      a {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    @include little-phone {
      height: 200px;
    }

    @include extra-phone-big {
      height: 175px;
    }

    @include extra-phone {
      height: 150px;
    }
  }

  .button-group {
    @include phone {
      padding: 0 10px;
      padding-bottom: 15px;
    }
  }
}

#content {
  padding-bottom: 30px;
}

.catalog-row {
  margin: 0;
  padding: 30px 0;

  &:before,
  &:after {
    display: none;
  }

  @include little-phone {
    padding-top: 0;
    padding-bottom: 15px;
  }
}

.catalog {
  &-list {
    margin: 0;
    display: flex;
    flex-wrap: wrap;

    &:before,
    &:after {
      display: none;
    }

    .row {
      margin: 0;

      &:before,
      &:after {
        display: none;
      }
    }
  }

  &-item {
    padding: 10px;
    width: 25%;

    @include menu-tablet {
      width: 33.3%;
    }

    @include portrait-tablet {
      width: 50%;
    }

    @include little-phone {
      padding: 5px;
    }

    &__content {
      display: block;
      box-shadow: 0 3px 20px rgba(#ffffff, 0.3);
      transition: 0.15s all linear;

      &:hover {
        box-shadow: 0 3px 20px rgba(#000000, 0.3);
      }
    }

    &__name {
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #3f2016;
      margin: 0;
      padding: 16px 0 20px;
      height: 80px;
      text-align: center;

      @include little-phone {
        padding: 5px 0;
        height: auto;
        font-size: 14px;
        line-height: 18px;
      }
    }

    &__img {
      width: 100%;
      height: 185px;
      object-fit: cover;

      @include little-phone {
        height: 100px;
      }
    }
  }
}

#cart {
  padding: 0;
  padding-left: 20px;

  @include desktop-little {
    padding-left: 5px;
  }

  @include menu-tablet {
    width: 100%;
  }

  @include tablet {
    position: absolute;
    top: 60px;
    right: 15px;
    width: auto;
    margin-top: 0;
  }

  // @include phone {
  //   position: unset;
  //   top: unset;
  //   right: unset;
  // }

  @include little-phone {
    top: 40px;
  }

  .dropdown-menu {
    max-width: 290px;
    width: 100vw;
  }

  .cart {
    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      @include menu-tablet {
        justify-content: flex-end;
      }

      &:hover {
        .cart {
          &__icon {
            fill: #ffffff;
          }

          &__content {
            color: #ffffff;
          }
        }
      }
    }

    &__icon {
      font-size: 45px;
      fill: #e7ff63;
      margin-left: 15px;
      transition: 0.15s all linear;

      @include tablet {
        margin-left: 0;
      }
    }

    &__content {
      transition: 0.15s all linear;
      display: flex;
      flex-direction: column;
      list-style: none;
      margin: 0;
      padding-left: 0;
      color: #3f2016;
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
    }

    &__items {
      font-size: 16px;
      line-height: 20px;
      font-weight: bold;

      &-tx {
        text-decoration: underline;

        @include little-phone {
          font-size: 14px;
          line-height: 18px;
          text-align: center;
        }

        @include small-phone {
          font-size: 12px;
          line-height: 16px;
        }
      }

      &-nums {
        text-decoration: none;
      }
    }

    &__price {
      padding-top: 5px;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.search {
  padding: 0;
  padding-right: 20px;

  @include desktop-little {
    padding-right: 5px;
  }

  @include menu-tablet {
    display: none;
  }
  @include little-phone {
    display: block;
    position: absolute;
    bottom: 10px;
    right: 0;
    width: 50%;
    align-items: flex-end;
    justify-content: flex-end;
  }

  &__form {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__input {
    border: none;
    border-radius: 5px;
    padding: 12px 15px;
    padding-left: 35px;
    width: 100%;
    font-size: 12px;
    line-height: 18px;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    color: #000000;
  }

  &__btn {
    position: absolute;
    background-color: transparent;
    border: none;
    width: 17px;
    height: 17px;
    padding: 0;
    margin: 0;
    left: 8px;
    margin-top: -2.5px;

    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

header {
  @include menu-tablet {
    position: relative;
  }

  @include tablet {
    min-height: unset;
  }

  .container {
    @include tablet {
      position: relative;
    }

    @include extra-phone-big {
      padding: 0 10px;
    }

    &:before,
    &:after {
      display: none;
    }
  }

  .row {
    margin: 0;

    &:before,
    &:after {
      display: none;
    }
  }
}

.header-top {
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include tablet {
      padding-top: 15px;
      padding-bottom: 45px;
    }

    @include phone {
      padding-bottom: 75px;
    }

    @include little-phone {
      padding-top: 10px;
      padding-bottom: 80px;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    padding: 0;
    position: unset;

    @include phone {
      width: calc(50% - 90px);
      justify-content: flex-end;
    }

    .cart {
      @include phone {
        &__link {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
        }

        &__content {
          display: none;
        }

        &__icon {
          margin-left: 0;
          margin-bottom: 5px;
        }
      }
    }
  }

  &__left {
    display: flex;
    align-items: center;
    padding: 0;
    position: unset;

    @include phone {
      width: calc(50% - 90px);
    }
  }
}

.header-bot {
  &__row {
    padding: 20px 0;

    @include menu-tablet {
      padding-bottom: 0;
    }

    @include tablet {
      padding: 0;
      height: 0;
    }
  }
}

.nav {
  @include menu-tablet {
    padding-bottom: 20px;
  }

  @include tablet {
    position: absolute;
    top: 60px;
    padding-bottom: 0;
    left: 15px;
  }

  @include phone {
    top: 75px;
  }

  @include little-phone {
    top: 45px;
  }

  &__item {
    padding: 0 15px;

    @include menu-tablet {
      padding: 0;
    }
  }

  &__btn {
    display: none;
    width: 40px;
    height: 30px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;

    @include menu-tablet {
      display: block;
    }

    .br {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: #ffffff;
      border-radius: 3px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;

      &-1 {
        top: 0px;
      }

      &-2 {
        top: 13px;
      }

      &-3 {
        top: 27px;
      }
    }

    &.opened {
      .br {
        &-1 {
          top: 18px;
          transform: rotate(135deg);
        }

        &-2 {
          opacity: 0;
          left: -60px;
        }

        &-3 {
          top: 18px;
          transform: rotate(-135deg);
        }
      }

      + .nav__list {
        transform: translateX(0);
      }
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;

    @include menu-tablet {
      background-color: #7fb323;
      position: absolute;
      flex-direction: column;
      align-items: stretch;
      z-index: 5;
      left: 0;
      top: 100%;
      padding: 10px;
      text-align: center;
      transition: 0.3s all linear;
      transform: translateX(-100%);
      width: 100%;
      max-width: 320px;
      // 08.07 fixes
      z-index: 12;
    }

    @include tablet {
      width: 100vw;
      left: -25px;
      top: calc(100% + 70px);
    }

    @include little-phone {
      top: calc(100% + 90px);
      width: 345px;
      max-width: 345px;
    }
  }

  &__link {
    display: block;
    padding: 5px 5px;
    text-transform: uppercase;
    color: #ffffff;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    transition: 0.15s all linear;

    &:hover {
      background-color: #fff;
    }
  }
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 250px;
  padding: 0 30px;

  @include tablet {
    max-width: 150px;
    padding: 0;
  }

  @include phone {
    max-width: 180px;
    padding: 0 15px;
  }

  @include little-phone {
    max-width: 150px;
  }

  &__link {
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__desc {
    padding-top: 10px;
    max-width: 170px;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-size: 12px;
    line-height: 16px;
    font-family: "Open Sans", sans-serif;
    font-weight: 500;

    @include tablet {
      display: none;
    }
  }
}

.phone {
  display: flex;
  align-items: center;
  padding: 0;

  @include tablet {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  @include little-phone {
    width: 50%;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 15px;
  }

  &__icon {
    fill: #3f2016;
    font-size: 45px;

    @include tablet {
      display: none;
    }
  }

  &__block {
    padding-left: 10px;

    @include tablet {
      padding-left: 0;
    }

    @include little-phone {
      display: flex;
      flex-direction: column;
    }
  }

  &__link {
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #3f2016;
    font-weight: bold;
    transition: 0.15s all linear;

    @include tablet {
      margin: 0 15px;
    }

    @include little-phone {
      margin: 0;
      text-align: center;
      font-size: 14px;
      line-height: 18px;
      width: 50%;
    }

    &:hover {
      color: #ffffff;
    }
  }
}

.address {
  font-size: 14px;
  line-height: 18px;
  color: #3f2016;
  font-weight: bold;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  a {
    margin-top: 10px;
  }

  &__col {
    padding: 0;
    display: flex;
    align-items: center;

    @include menu-tablet {
      display: none;
    }
  }

  &__icon {
    fill: #3f2016;
    font-size: 50px;
    width: 1em;
    margin-right: 5px;
  }

  &__link {
    margin-top: 10px;
  }
}

.benefits-row {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0;

  &:before,
  &:after {
    display: none;
  }

  @include tablet {
    display: none;
  }
}

.benefit {
  width: 25%;
  padding: 0 10px;

  @include menu-tablet {
    width: 50%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  @include custom-phone {
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    @include menu-tablet {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  &__desc {
    font-size: 14px;
    font-weight: 20px;
    font-family: sans-serif;
    color: #737373;
  }

  &__img {
    margin-right: 10px;

    @include menu-tablet {
      margin-right: 0;
      margin-bottom: 10px;
    }

    img {
      height: 35px;
      width: auto;
    }
  }

  &__name {
    margin: 0;
    font-size: 16px;
    color: #3f2016;
    font-family: Philosopher;
    font-weight: 700;
    max-width: 140px;
  }
}

#slideshow0 {
  .item {
    img {
      width: 100%;
      height: 390px;
      object-fit: contain;

      @include tablet {
        height: 350px;
      }

      @include big-phone {
        height: 250px;
      }

      @include little-phone {
        height: 150px;
      }
    }
  }
}

.row-title {
  cursor: inherit;
  height: auto;
  color: #3f2016;
  font-family: Philosopher;
  font-size: 40px;
  font-weight: 700;
  text-transform: unset;
  text-align: center;
  background-color: transparent;
  box-shadow: none;
  margin: 0;
  padding-bottom: 30px;

  &:after {
    display: none;
  }

  @include little-phone {
    padding: 0;
    padding-bottom: 15px;
    font-size: 30px;
    line-height: 36px;
  }
}

// footer

footer {
  margin-top: 30px;

  @include tablet {
    padding: 20px 0;
  }

  .container {
    &:before,
    &:after {
      display: none;
    }
  }
}

.footer {
  font-family: "Open Sans", sans-serif;

  &__row {
    display: flex;
    justify-content: space-between;
    margin: 0;

    &:before,
    &:after {
      display: none;
    }

    @include tablet {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .row {
      margin: 0;

      &:before,
      &:after {
        display: none;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    @include tablet {
      align-items: center;
    }

    &-col {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &-row {
      display: flex;
      align-items: center;

      @include tablet {
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  &-logo {
    padding: 0;

    &__link {
      display: block;
      width: 100%;
      max-width: 190px;
    }

    &__img {
      width: 100%;
      height: auto;
    }

    &__desc {
      color: #ffffff;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
    }
  }

  &__copyright {
    padding-top: 20px;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
  }

  &__phones {
    display: flex;
    align-items: center;
    padding: 0;
    padding-right: 15px;

    @include tablet {
      padding-right: 0;
    }

    .phone {
      &__block {
        display: flex;
        flex-direction: column;

        @include tablet {
          padding-left: 0;
          display: flex;
          align-items: center;
          padding-bottom: 10px;
        }
      }

      &__icon {
        fill: #ffffff;
        font-size: 3rem;

        @include tablet {
          display: none;
        }
      }

      &__link {
        color: #ffffff;

        @include tablet {
          width: 100%;
          padding: 0 5px;
          margin: 0;
        }

        &:hover {
          color: #3f2016;
        }
      }
    }
  }

  &__order-call {
    padding: 0;

    .order-call-btn {
      border-radius: 5px;
    }
  }

  &__social {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;

    @include tablet {
      flex-wrap: wrap;
      justify-content: center;
    }

    .social {
      &__item {
        @include tablet {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &-title {
          text-transform: uppercase;
          color: #ffffff;
          font-size: 12px;
          line-height: 16px;
          font-weight: 500;
          margin-right: 10px;

          @include tablet {
            width: 100%;
            margin-right: 0;
            padding-bottom: 15px;
            text-align: center;
          }
        }
      }

      &__link {
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: #ffffff;
        border: 2px solid #f2ffa8;
        transition: 0.15s all linear;

        &:hover {
          border-color: #7fb323;
          background-color: #7fb323;

          .social__icon {
            fill: #ffffff;
          }
        }
      }

      &__icon {
        transition: 0.15s all linear;
        fill: #7fb323;
        font-size: 24px;
      }
    }
  }
}

.owl-controls .owl-buttons {
  .owl-prev {
    @include menu-tablet {
      left: 30% !important;
      top: unset !important;
      bottom: 60px !important;
    }

    @include custom-phone {
      left: 25% !important;
    }
  }

  .owl-next {
    @include menu-tablet {
      right: 30% !important;
      top: unset !important;
      bottom: 60px !important;
    }

    @include custom-phone {
      right: 25% !important;
    }
  }
}

.btn-group.open .dropdown-toggle {
  box-shadow: none;
}

// 08.07
.product-layout {
  @include phone {
    padding: 0 5px;
  }

  &.col-xs-12 {
    @include portrait-tablet {
      width: 50%;
    }
  }
}
